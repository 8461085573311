/* You can add global styles to this file, and also import other style files */

body {
  /*background-color: black;
  color: white !important;*/
  /*background-color: whitesmoke;*/
  /*background-color: whitesmoke;*/
  /*background: transparent !important;*/
  background: transparent !important;
  color: black !important;
}

hr {
    border-color: white;
}

.puntero {
    cursor: pointer;
}

.img-circle {
    border-radius: 100%;
    width: 150px;
}

.card-title,
.card-text {
    color: black;
}

.badge {
    margin-left: 5px;
}

.btn-outline-success {
    margin-left: 5px;
}

.img-thumb {
    width: 50px;
    height: 50px;
}


/* Animaciones */

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.fast {
    -webkit-animation-duration: 0.4s;
    animation-duration: 0.4s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

/*@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}*/

.fadeIn {
    animation-name: fadeIn;
}



.globusPadding_2x {
  padding: 2px;
}

.globusPadding_5x {
  padding: 5px;
}

.globusMargin_2x {
  margin: 2px;
}

.globusMargin_5x {
  margin: 5px;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.mainNew {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  background: url(assets/img/fondo5.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.mainPpal {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0 auto;
  background: url(assets/img/fondo4.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.contenedorLogin {
  background-color: #F7F8F9;
  /*background-color: #FFFFFF;*/
  padding-top: 4%;
  padding-bottom: 4%;
  -moz-box-shadow: 0 0 3rem white;
  -webkit-box-shadow: 0 0 3rem white;
  box-shadow: 0 0 3rem white;
  border-radius: 0.4rem;
  border: 1px solid #CCCCCC;
}


/****************************************************Poppins****************************************************/
@font-face {
  font-family: 'poppins-black';
  src: url('../src/assets/fonts/Poppins/Poppins-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-BlackItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../src/assets/fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-BoldItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('../src/assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraBoldItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLight';
  src: url('../src/assets/fonts/Poppins/Poppins-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ExtraLightItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Italic';
  src: url('../src/assets/fonts/Poppins/Poppins-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('../src/assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-LightItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../src/assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-MediumItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../src/assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../src/assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-SemiBoldItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Thin';
  src: url('../src/assets/fonts/Poppins/Poppins-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-ThinItalic';
  src: url('../src/assets/fonts/Poppins/Poppins-ThinItalic.ttf') format('truetype');
}
/****************************************************Poppins****************************************************/


/****************************************************Roboto****************************************************/
@font-face {
  font-family: 'roboto-black';
  src: url('../src/assets/fonts/Roboto/roboto-black.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-blackitalic';
  src: url('../src/assets/fonts/Roboto/roboto-blackitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-bold';
  src: url('../src/assets/fonts/Roboto/roboto-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-bolditalic';
  src: url('../src/assets/fonts/Roboto/roboto-bolditalic.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-italic';
  src: url('../src/assets/fonts/Roboto/roboto-italic.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-light';
  src: url('../src/assets/fonts/Roboto/roboto-light.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-lightitalic';
  src: url('../src/assets/fonts/Roboto/roboto-lightitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-medium';
  src: url('../src/assets/fonts/Roboto/roboto-medium.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-mediumitalic';
  src: url('../src/assets/fonts/Roboto/roboto-mediumitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-regular';
  src: url('../src/assets/fonts/Roboto/roboto-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-thin';
  src: url('../src/assets/fonts/Roboto/roboto-thin.ttf') format('truetype');
}
@font-face {
  font-family: 'roboto-thinitalic';
  src: url('../src/assets/fonts/Roboto/roboto-thinitalic.ttf') format('truetype');
}
/****************************************************Roboto****************************************************/

/****************************************************SourceSansPro****************************************************/
@font-face {
  font-family: 'sourcesanspro-black';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-black.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-blackitalic';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-blackitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-bold';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-bold.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-bolditalic';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-bolditalic.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-extralight';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-extralight.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-extralightitalic';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-extralightitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-italic';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-italic.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-light';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-light.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-lightitalic';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-lightitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-regular';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-regular.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-semibold';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-semibold.ttf') format('truetype');
}
@font-face {
  font-family: 'sourcesanspro-semibolditalic';
  src: url('../src/assets/fonts/SourceSansPro/sourcesanspro-semibolditalic.ttf') format('truetype');
}
/****************************************************SourceSansPro****************************************************/


* {
  font-family: Poppins-Regular !important;
}

.fuenteLigera {
  font-family: Poppins-Light !important;
}

.fuenteRegular {
  font-family: Poppins-Regular !important;
}

.fuenteMedia {
  font-family: Poppins-Medium !important;
}

.fuenteNegrita {
  font-family: Poppins-Bold !important;
}

.fuenteNegritaSemi {
  font-family: Poppins-SemiBold !important;
}

.fuenteNegritaExtra {
  font-family: Poppins-ExtraBold !important;
}

.fuente_0_5rem{
  font-size: 0.5rem;
}

.fuente_0_6rem {
  font-size: 0.6rem;
}

.fuente_0_7rem {
  font-size: 0.7rem;
}

.fuente_0_8rem {
  font-size: 0.8rem;
}

.fuente_0_9rem {
  font-size: 0.9rem;
}

.fuente_1rem {
  font-size: 1rem;
}

.fuente_1_1rem {
  font-size: 1.1rem;
}

.fuente_1_2rem {
  font-size: 1.2rem;
}

.fuente_1_3rem {
  font-size: 1.3rem;
}

.fuente_1_4rem {
  font-size: 1.4rem;
}

.fuente_1_5rem {
  font-size: 1.5rem;
}

.fuente_1_6rem {
  font-size: 1.6rem;
}

.fuente_1_7rem {
  font-size: 1.7rem;
}

.fuente_1_8rem {
  font-size: 1.8rem;
}

.fuente_1_9rem {
  font-size: 1.9rem;
}

.fuente_2rem {
  font-size: 2rem;
}




@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, .15);
  -webkit-transition: width .6s ease;
  -o-transition: width .6s ease;
  transition: width .6s ease;
}

.progress-striped .progress-bar,
.progress-bar-striped {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress.active .progress-bar,
.progress-bar.active {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.progress-bar-default {
  background-color: #555555;
}

.progress-striped .progress-bar-default {
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}



.formatted-address {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 110px;
  font-family: "Poppins-SemiBold", Helvetica, Arial, sans-serif;
  font-size: 10px;
  padding: 0 0px;
}


.visitaFila :hover {
  background-color: orangered;
  color: white;
  font-size: 1.2rem;
}

.ListadoInformacionLoading {
  max-height: 450px;
  width: 500px;
  max-width: 500px;
}


.ListadoInformacion {
  max-height: 450px;
  width: 500px;
  max-width: 500px;
  overflow-y: scroll;
}

  .ListadoInformacion::-webkit-scrollbar {
    -webkit-appearance: none;
  }

    .ListadoInformacion::-webkit-scrollbar:vertical {
      width: 10px;
    }

  .ListadoInformacion::-webkit-scrollbar-button:increment, .ListadoInformacion::-webkit-scrollbar-button {
    display: none;
  }

  .ListadoInformacion::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  .ListadoInformacion::-webkit-scrollbar-thumb {
    /*background: #ccc;*/
    background: #007bff;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

    .ListadoInformacion::-webkit-scrollbar-thumb:hover {
      background: #3796FF;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    .ListadoInformacion::-webkit-scrollbar-thumb:active {
      background-color: #007bff;
    }

  .ListadoInformacion::-webkit-scrollbar-track {
    border-radius: 10px;
  }


.pac-container {
  z-index: 1051;
}

.map200 {
  width: 100%;
  height: 200px;
}

.map300 {
  width: 100%;
  height: 300px;
}

.map400 {
  width: 100%;
  height: 400px;
}

.map400 {
  width: 100%;
  height: 500px;
}

.radius-0 {
  border-radius: 0rem !important;
  /*background-color: white !important;*/
}

.modalLoading {
  z-index: 1000000 !important;
  padding-top: 9%;
  /*background-color: rgba(255, 255, 255, 0.5);*/ /*BLANCO*/
  /*background-color: rgba(0, 148, 255, 0.2);*/ /*AZUL*/
  /*background-color: rgba(255, 0, 0, 0.1);*/ /*ROJO*/
  /*background-color: rgba(0, 0, 0, 0);*/ /*NEGRO*/
  background-color: rgba(128, 128, 128, 0.4); /*GRIS CLARO*/
}

.modalMensajes {
  z-index: 1000000 !important;
  padding-top: 9%;
  background-color: rgba(0, 0, 0, 0.8);
}

.modalCrearTercero {
  z-index: 1000000 !important;
  padding-top: 1%;
  background-color: rgba(0, 0, 0, 0.8);
}

.visitaNegocio :hover {
  border: 1px solid orangered !important;
}

.cardVisita:first-child:hover {
  /*border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;*/
  /*border: 1px solid #dc3545;*/
  /*border-left: 2px solid #dc3545;*/
  background-color: rgba(255, 106, 0, 0.02);
  cursor: pointer;
}

.cardVisitaBorder:first-child:hover {
  /*border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;*/
  border: 1px solid #dc3545;
  /*border-left: 2px solid #dc3545;*/
  background-color: rgba(255, 106, 0, 0.02);
  cursor: pointer;
}

.cardVisita:first-child:active {
  background-color: rgba(0, 0, 0, .05);
}

.cardMenuVentaBorder:first-child {
  border-bottom: 1px solid #F2F2F2 !important;
}

.cardMenuVentaBorder:first-child:hover {
  border-bottom: 1px solid #DBDAD9 !important;
}

  .cardMenuVentaBorder:first-child:active {
    /*background-color: rgba(0, 0, 0, .05);*/
    border-bottom: 1px solid orangered !important;
  }

.cardNegocioBorder:first-child {
  /*border-bottom: 1px solid #F2F2F2 !important;*/
  border : 1px solid #F2F2F2 !important;
  padding: 5px 5px 5px 5px !important;
}

  .cardNegocioBorder:first-child:hover {
    /*border-bottom: 1px solid #DBDAD9 !important;*/
    border: 1px solid #DBDAD9 !important;
    padding: 5px 5px 5px 5px !important;
  }

  .cardNegocioBorder:first-child:active {
    border-bottom: 1px solid orangered !important;
    /*border: 1px solid orangered !important;*/
    padding: 5px 5px 5px 5px !important;
  }


.cardMenuDetalleBorder:first-child {
  border-bottom: 1px solid #F2F2F2 !important;
}

  .cardMenuDetalleBorder:first-child:hover {
    border-bottom: 1px solid #DBDAD9 !important;
  }

  .cardMenuDetalleBorder:first-child:active {
    border-bottom: 1px solid orangered !important;
  }

.cardMenuDetalleArticuloBorder:first-child {
  border-bottom: 1px solid #F2F2F2 !important;
}

  .cardMenuDetalleArticuloBorder:first-child:hover {
    border-bottom: 1px solid #DBDAD9 !important;
  }

  .cardMenuDetalleArticuloBorder:first-child:active {
    border-bottom: 1px solid orangered !important;
  }


.cardMenuDetallePersonalizarBorder:first-child {
  border-bottom: 1px solid #F2F2F2 !important;
}

  .cardMenuDetallePersonalizarBorder:first-child:hover {
    border-bottom: 1px solid #DBDAD9 !important;
    color: #222222 !important;
  }

  .cardMenuDetallePersonalizarBorder:first-child:active {
    border-bottom: 1px solid orangered !important;
    color: orangered !important;
  }


.cardTerceroBuscarBorder:first-child {
  border-bottom: 1px solid #F2F2F2 !important;
}

  .cardTerceroBuscarBorder:first-child:hover {
    border-bottom: 1px solid #DBDAD9 !important;
    color: #222222 !important;
  }

  .cardTerceroBuscarBorder:first-child:active {
    border-bottom: 1px solid orangered !important;
    color: orangered !important;
  }

.TextoDegradado {
  background-color: #FFFFFF;
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.btnMenu:hover {
  background-image: linear-gradient(100deg, #FFFFFF 0, #FFFFFF 100%) !important;
  border: 1px solid #CCCCCC;
  border-left: 2px solid orangered;
}
.btnMenu:active {
  background-image: linear-gradient(100deg, #EEEEEE 0, #EEEEEE 100%) !important;
  border: 1px solid orangered;
}

.btnMenuColor:hover {
  color: orangered !important;
}
.btnMenuColor:active {
  color: #F92C00 !important;
}

.box {
  object-fit: cover;
}

  .box img {
    max-height: 200px;
  }

@supports(object-fit: cover) {
  .box img {
    object-fit: cover;
  }
}

.boxMenu {
  object-fit: cover;
}

  .boxMenu img {
    max-height: 90px;
  }

@supports(object-fit: cover) {
  .boxMenu img {
    object-fit: cover;
  }
}

.boxMenuDetalle {
  object-fit: cover;
}

.boxMenuDetalle img {
  max-height: 250px;
  max-width: 400px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

@supports(object-fit: cover) {
  .boxMenuDetalle img {
    object-fit: cover;
  }
}


.boxNegocio {
  object-fit: cover;
}

  .boxNegocio img {
    max-height: 140px;
    max-width: 400px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

@supports(object-fit: cover) {
  .boxNegocio img {
    object-fit: cover;
  }
}

.boxNegocioListado {
  object-fit: cover;
}

  .boxNegocioListado img {
    max-height: 140px;
    max-width: 400px;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

@supports(object-fit: cover) {
  .boxNegocioListado img {
    object-fit: cover;
  }
}

.boxReferencia {
  object-fit: cover;
}

.boxReferencia img {
  max-height: 100px !important;
}

@supports(object-fit: cover) {
  .boxReferencia img {
    object-fit: cover;
  }
}

.boxMenuDetalleArticulo {
  object-fit: cover;
}

  .boxMenuDetalleArticulo img {
    max-height: 450px !important;
  }

@supports(object-fit: cover) {
  .boxMenuDetalleArticulo img {
    object-fit: cover;
  }
}

.boxReferenciaPersonalizar {
  object-fit: cover;
}

  .boxReferenciaPersonalizar img {
    max-width: 50px !important;
    /*max-height: 100px !important;*/
  }

@supports(object-fit: cover) {
  .boxReferenciaPersonalizar img {
    object-fit: cover;
  }
}


.rounded-05rem {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.rounded-1rem {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.radius-500 {
  border-radius: 500px !important;
}

.espacioInterlinea {
  line-height: 1rem;
}


.modal-xl {
  min-width: calc(100% - 1rem) !important;
  max-width: calc(100% - 1rem) !important;
}

@media (min-width: 576px) {
  .modal-xl {
    min-width: calc(100% - 5rem) !important;
    max-width: calc(100% - 5rem) !important;
  }
}

@media (min-width: 768px) {
  .modal-xl {
    min-width: calc(100% - 10rem) !important;
    max-width: calc(100% - 10rem) !important;
  }
}

@media (min-width: 992px) {
  .modal-xl {
    min-width: calc(100% - 20rem) !important;
    max-width: calc(100% - 20rem) !important;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    min-width: calc(100% - 25rem) !important;
    max-width: calc(100% - 25rem) !important;
  }
}

.modal-xxl {
  min-width: calc(100% - 1rem) !important;
  max-width: calc(100% - 1rem) !important;
}

@media (min-width: 576px) {
  .modal-xxl {
    min-width: calc(100% - 1rem) !important;
    max-width: calc(100% - 1rem) !important;
  }
}

@media (min-width: 768px) {
  .modal-xxl {
    min-width: calc(100% - 5rem) !important;
    max-width: calc(100% - 5rem) !important;
  }
}

@media (min-width: 992px) {
  .modal-xxl {
    min-width: calc(100% - 10rem) !important;
    max-width: calc(100% - 10rem) !important;
  }
}

@media (min-width: 1200px) {
  .modal-xxl {
    min-width: calc(100% - 15rem) !important;
    max-width: calc(100% - 15rem) !important;
  }
}

.modal-xxxl {
  min-width: calc(100% - 1rem) !important;
  max-width: calc(100% - 1rem) !important;
}

@media (min-width: 576px) {
  .modal-xxxl {
    min-width: calc(100% - 1rem) !important;
    max-width: calc(100% - 1rem) !important;
  }
}

@media (min-width: 768px) {
  .modal-xxxl {
    min-width: calc(100% - 3rem) !important;
    max-width: calc(100% - 3rem) !important;
  }
}

@media (min-width: 992px) {
  .modal-xxxl {
    min-width: calc(100% - 4rem) !important;
    max-width: calc(100% - 4rem) !important;
  }
}

@media (min-width: 1200px) {
  .modal-xxxl {
    min-width: calc(100% - 5rem) !important;
    max-width: calc(100% - 5rem) !important;
  }
}

.modalMenuPedido {
  width: 100% !important;
  overflow-y: auto !important;
}

.modal-PedidoCallCenter {
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 0% !important;
}

.modal-MenuVenta {
  background-color: rgba(255, 255, 255, 1) !important;
}

.modal-MenuDetalleArticulo {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.modal-MenuDetalleTopping {
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.modal-PersonalizarSUB {
  padding-top: 3%;
}

.modal-PersonalizarTopping {
  padding-top: 3%;
}

.modal-PedidoCallCenter {
  min-width: 100% !important;
  max-width: 100% !important;
  margin-top: 0% !important;
}

.modal-scroll {
  height: 100% !important;
  min-height: 100% !important;
  overflow-y: auto !important;
}

.btn-Redondo {
  border-radius: 25px;
  position: relative;
  width: 48px;
  height: 48px;
  -webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%), 5px 10px 30px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%), 5px 10px 30px 0 rgb(0 0 0 / 7%);
  cursor: pointer;
}

.btn-Redondo-24 {
  border-radius: 20px;
  /*position: relative;*/
  width: 24px;
  height: 24px;
  /*-webkit-box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%), 5px 10px 30px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%), 5px 10px 30px 0 rgb(0 0 0 / 7%);*/
  cursor: pointer;
}

.Flotante-Izquierda {
  position: fixed;
  left: 1%;
  top: 1%;
  z-index: 9999;
}

.Flotante-Derecha {
  position: fixed;
  right: 1%;
  top: 1%;
  z-index: 9999;
}

.fondo-BlancoHumo {
  background-color: #F9F9F8 !important;
}

.fondo-Gris {
  background-color: #DBDAD9 !important;
}

.fondo-GrisSuave {
  background-color: #F2F2F2 !important;
}

.shadow-bottom {
  -webkit-box-shadow: 0 10px 6px -6px rgba(0,0,0,0.16);
  -moz-box-shadow: 0 10px 6px -6px rgba(0,0,0,0.16);
  box-shadow: 0 10px 6px -6px rgba(0,0,0,0.16);
}

.shadow-top {
  /*-webkit-box-shadow: 0 10px 6px -6px rgba(0,0,0,0.16);
  -moz-box-shadow: 0 10px 6px -6px rgba(0,0,0,0.16);
  box-shadow: 0 10px 6px -6px rgba(0,0,0,0.16);*/
  /*box-shadow: 0 -6px 1px rgba(0,0,0,0.16);*/
  /*box-shadow: -10px -4.5rem 40px 0 rgba(0,0,0,0.16);*/
  box-shadow: 0 -10px 6px -6px rgba(0,0,0,0.16);
}

.input-number {
    border: 1px solid #e5edef;
    border-radius: 4px;
    padding: 2px;
    transition: all .3s linear;
}

.input-number .subtract {
    color: #332927;
    background-color: #EFEFEF;
    padding: 6px 16px;
    border-radius: 4px;
    transition: all .3s linear;
}

.input-number .subtract:hover {
    background-color: #F92C00;
    color: #fff;
}

.input-number .value {
    transition: all .3s linear;
    min-width: 40px;
    overflow: hidden;
    padding: 0 5px;
    text-align: center;
}


.input-personalizar {
    border: 1px solid #e5edef;
    border-radius: 4px;
    transition: all .3s linear;
}

.input-personalizar .subtract {
    color: #F92C00;
    background-color: #FFFFFF;
    padding: 6px 16px;
    border-radius: 4px;
    transition: all .3s linear;
    font-size: 0.8rem !important;
}

.input-personalizar .subtract:hover {
    background-color: #F92C00;
    color: #fff;
}

.input-personalizar .value {
    transition: all .3s linear;
    min-width: 40px;
    overflow: hidden;
    padding: 0 5px;
    text-align: center;
}


.input-menu {
    width: 100%;
    border: 1px solid #e5edef;
    border-radius: 4px;
    transition: all .3s linear;
}

.input-menu .subtract {
    width: 100%;
    padding: 18px 8px;
    border-radius: 4px;
    transition: all .3s linear;
}

.input-menu .subtract:hover {
    transition: all .1s linear;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    border: 2px solid #e5edef;
}
  .input-menu .subtract:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 4px;
    border: none;
  }

  .input-menu .subtract:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-image: linear-gradient(130deg,#F92C00 0,#F92C00 100%);
    transition: all .1s linear;
    z-index: -1;
    border-radius: 4px;
    border: none;
  }

  .input-menu .subtract:hover {
    color: #fff;
}
  .input-menu .subtract:before {
    width: 100%;
  }


.input-menu .value {
  width: 100%;
  transition: all .3s linear;
  min-width: 40px;
  overflow: hidden;
  padding: 0 5px;
  text-align: center;
}

.input-menu .description {
    width: 100%;
    min-width: 40px;
    overflow: hidden;
    padding: 0 5px;
    opacity: 0.7;
}


.input-seleccionar {
    width: 100%;
    border: 1px solid #e5edef;
    border-radius: 4px;
    transition: all .3s linear;
}

.input-seleccionar .subtract {
    width: 100%;
    padding: 6px 8px;
    border-radius: 4px;
    transition: all .3s linear;
}

.input-seleccionar .subtract:hover {
    transition: all .1s linear;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    border: 2px solid #e5edef;
}

  .input-seleccionar .subtract:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 4px;
    border: none;
  }

  .input-seleccionar .subtract:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-image: linear-gradient(130deg,#F92C00 0,#F92C00 100%);
    transition: all .1s linear;
    z-index: -1;
    border-radius: 4px;
    border: none;
  }

  .input-seleccionar .subtract:hover {
    color: #fff;

}
  .input-seleccionar .subtract:before {
    width: 100%;
  }

.input-seleccionar .value {
  width: 100%;
  transition: all .3s linear;
  min-width: 40px;
  overflow: hidden;
  padding: 0 5px;
  text-align: center;
}

.input-seleccionar .description {
    width: 100%;
    min-width: 40px;
    overflow: hidden;
    padding: 0 5px;
    opacity: 0.7;
}



.input-menu-imagen {
    width: 100%;
    /*border: 1px solid #e5edef;*/
    transition: all .3s linear;
    border-radius: 4px 4px;
}

.input-menu-imagen .subtract {
    width: 100%;
    padding: 8px 8px;
    transition: all .3s linear;
}

.input-menu-imagen .image {
    width: 100%;
    border-radius: 4px 4px;
}

.input-menu-imagen .image:hover {
    width: 100%;
    opacity: 0.3;
    border-radius: 4px 4px;
}

.input-menu-imagen .subtract:hover {
    transition: all .1s linear;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    border: 2px solid #e5edef;
}

  .input-menu-imagen .subtract:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
    border-radius: 4px;
    border: none;
  }

  .input-menu-imagen .subtract:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-image: linear-gradient(130deg,#F92C00 0,#F92C00 100%);
    transition: all .1s linear;
    z-index: -1;
    border-radius: 4px;
    border: none;
    opacity: 0.3;
  }

  .input-menu-imagen .subtract:hover {
    color: #fff;
}

  .input-menu-imagen .subtract:before {
    width: 100%;
  }

.cont-view-product {
  background-color: #fafaf8 !important;
}

  .cont-img-product {
    min-width: 200px;
    height: 100%;
    overflow: hidden;
  }

.cont-img-product .content-img {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  padding: 30px 20px 30px 30px;
  border-radius: 4px 0 0 4px;
  background-size: cover;
  height: 100%;
  font-size: 0;
}

  .cont-img-product .content-img img {
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    -o-object-fit: cover;
    object-fit: cover;
    height: 100px;
  }

@media (min-width: 576px) {
  .cont-img-product .content-img img {
    height: 100%;
  }
}

@media (min-width: 768px) {
  .cont-img-product .content-img img {
    height: 100%;
  }
}

@media (min-width: 992px) {
  .cont-img-product .content-img img {
    height: 100%;
  }
}

@media (min-width: 1200px) {
  .cont-img-product .content-img img {
    height: 100%;
  }
}

.cont-img-product {
  flex: 1;
}

.close-modal {
  position: absolute;
  top: 0.5% !important;
  right: 0.5% !important;
  z-index: 1000;
}

.close-modal-left {
  position: absolute;
  top: 0.5% !important;
  left: 0.5% !important;
  z-index: 1000;
}

.product-content {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
  max-height: 350px;
}

  .product-content::-webkit-scrollbar {
    -webkit-appearance: none;
  }
    .product-content::-webkit-scrollbar:vertical {
      width: 10px;
    }

  .product-content::-webkit-scrollbar-button:increment, .product-content::-webkit-scrollbar-button {
    display: none;
  }

  .product-content::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  .product-content::-webkit-scrollbar-thumb {
    /*background: #ccc;*/
    background: #007bff;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

    .product-content::-webkit-scrollbar-thumb:hover {
      background: #3796FF;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    .product-content::-webkit-scrollbar-thumb:active {
      background-color: #007bff;
    }

  .product-content::-webkit-scrollbar-track {
    border-radius: 10px;
  }

@media (min-width: 576px) {
  .product-content {
    max-height: 480px;
  }
}

@media (min-width: 768px) {
  .product-content {
    max-height: 480px;
  }
}

@media (min-width: 992px) {
  .product-content {
    max-height: 480px;
  }
}

@media (min-width: 1200px) {
  .product-content {
    max-height: 480px;
  }
}


.badge-pink {
  color: #FE4C4D;
  background-color: #FAEEED;
}

a.badge-pink:hover, a.badge-pink:focus {
  color: #FE4C4D;
  background-color: #F2D5D2;
}

a.badge-pink:focus, a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.carrito-info-items {
  position: absolute;
  bottom: -30% !important;
  left: 25% !important;
  /*z-index: 1000;*/
}

.modal-CarritoCompra {
  /*z-index: 9999 !important;*/
  /*z-index: 2000 !important;*/
  padding-top: 0%;
  background-color: rgba(0, 0, 0, 0.8);
}

.product-content-topping {
  -webkit-overflow-scrolling: touch;
  width: 100%;
  flex: 1;
  max-height: 350px;
  overflow: hidden;
  overflow-y: auto;
}

  .product-content-topping::-webkit-scrollbar {
    -webkit-appearance: none;
  }

    .product-content-topping::-webkit-scrollbar:vertical {
      width: 10px;
    }

  .product-content-topping::-webkit-scrollbar-button:increment, .product-content-topping::-webkit-scrollbar-button {
    display: none;
  }

  .product-content-topping::-webkit-scrollbar:horizontal {
    height: 10px;
  }

  .product-content-topping::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
  }

    .product-content-topping::-webkit-scrollbar-thumb:hover {
      background: #b3b3b3;
      box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    }

    /* Cambiamos el fondo cuando esté en active */
    .product-content-topping::-webkit-scrollbar-thumb:active {
      background-color: #999999;
    }

  .product-content-topping::-webkit-scrollbar-track {
    border-radius: 10px;
  }


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.boxFondo {
  object-fit: cover;
}

  .boxFondo img {
    max-height: 250px;
    max-width: 400px;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

@supports(object-fit: cover) {
  .boxFondo img {
    object-fit: cover;
  }
}

.text-purple {
  color: #F06292 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #F06292 !important;
}

.border-purple {
  border-color: #F06292 !important;
}


.text-blue {
  color: #33B5E7 !important;
}

a.text-blue:hover, a.text-blue:focus {
  color: #33B5E7 !important;
}

.border-blue {
  border-color: #33B5E7 !important;
}

.badge-blue {
  color: #fff;
  background-color: #1D9FF3;
}

a.badge-blue:hover, a.badge-blue:focus {
  color: #fff;
  background-color: #0D94EE;
}

a.badge-blue:focus, a.badge-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #6D1BFD;
}

a.badge-purple:hover, a.badge-purple:focus {
  color: #fff;
  background-color: #6306FD;
}

a.badge-purple:focus, a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-green {
  color: #fff;
  background-color: #0cc665;
}

a.badge-green:hover, a.badge-green:focus {
  color: #fff;
  background-color: #0cc665;
}

a.badge-green:focus, a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-orange {
  color: #fff;
  background-color: #FF441F;
}

a.badge-orange:hover, a.badge-orange:focus {
  color: #fff;
  background-color: #FF441F;
}

a.badge-orange:focus, a.badge-orange.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-lightBlue {
  color: #0D94EE;
  background-color: #DCE5F9;
}

a.badge-lightBlue:hover, a.badge-lightBlue:focus {
  color: #0D94EE;
  background-color: #7DBFF9;
}

a.badge-lightBlue:focus, a.badge-lightBlue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.badge-lightRed {
  color: #FF4448;
  background-color: #FFE3DD;
}

a.badge-lightRed:hover, a.badge-lightRed:focus {
  color: #FF4448;
  background-color: #FFE3DD;
}

a.badge-lightRed:focus, a.badge-lightRed.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

.bg-lightWarning {
  background-color: #F7EBC7 !important;
}

a.bg-lightWarning:hover, a.bg-lightWarning:focus,
button.bg-lightWarning:hover,
button.bg-lightWarning:focus {
  background-color: #F7EBC7 !important;
}

.bg-lightSuccess {
  background-color: #CDE6D2 !important;
}

a.bg-lightSuccess:hover, a.bg-lightSuccess:focus,
button.bg-lightSuccess:hover,
button.bg-lightSuccess:focus {
  background-color: #CDE6D2 !important;
}


.GMap_Direccion {
  background-color: #FCFCFC;
  border-bottom: 1px solid #cccccc !important;
  border-left: 1px solid #cccccc !important;
  border-right: 1px solid #cccccc !important;
  color: #222222;
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  cursor: pointer;
}

  .GMap_Direccion:hover {
    background-color: #F8F8F8;
    color: #999999;
    /*box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);*/
  }

  .GMap_Direccion:active {
    background-color: #ffc7bc;
  }


.search_Direccion {
  position: relative;
  /*box-shadow: 0 0 40px rgba(51, 51, 51, .1)*/
}

  .search_Direccion input {
    /*height: 60px;*/
    text-indent: 25px;
    /*border: 2px solid #d6d4d4*/
  }

    /*.search_Direccion input:focus {
      box-shadow: none;
      border: 2px solid blue
    }*/

  .search_Direccion .fa-map-marker {
    position: absolute;
    top: 20px;
    left: 16px
  }

  .search_Direccion .fa-map-marker-alt {
    position: absolute;
    top: 17px;
    left: 16px
  }

  /*.search_Direccion button {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 50px;
    width: 110px;
    background: blue
  }*/


@-webkit-keyframes gwd-cuadroRojoAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #EF5E60;
    box-shadow: 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60;
    -webkit-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #EF5E60;
    box-shadow: 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60;
    -webkit-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }
}

@-moz-keyframes gwd-cuadroRojoAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #EF5E60;
    box-shadow: 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60;
    -moz-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #EF5E60;
    box-shadow: 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60;
    -moz-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }
}

@keyframes gwd-cuadroRojoAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #EF5E60;
    box-shadow: 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60;
    animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #EF5E60;
    box-shadow: 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60, 0 0 0.3em #EF5E60;
    animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }
}

body .gwd-cuadroRojoAnimation {
  -webkit-animation: gwd-cuadroRojoAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  -moz-animation: gwd-cuadroRojoAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  animation: gwd-cuadroRojoAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
}

@-webkit-keyframes gwd-cuadroVerdeAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #388138;
    box-shadow: 0 0 0.3em #388138, 0 0 0.3em #388138, 0 0 0.3em #388138;
    -webkit-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #388138;
    box-shadow: 0 0 0.3em #388138, 0 0 0.3em #388138, 0 0 0.3em #388138;
    -webkit-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }
}

@-moz-keyframes gwd-cuadroVerdeAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #388138;
    box-shadow: 0 0 0.3em #388138, 0 0 0.3em #388138, 0 0 0.3em #388138;
    -moz-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #388138;
    box-shadow: 0 0 0.3em #388138, 0 0 0.3em #388138, 0 0 0.3em #388138;
    -moz-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }
}

@keyframes gwd-cuadroVerdeAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #388138;
    box-shadow: 0 0 0.3em #388138, 0 0 0.3em #388138, 0 0 0.3em #388138;
    animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #388138;
    box-shadow: 0 0 0.3em #388138, 0 0 0.3em #388138, 0 0 0.3em #388138;
    animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }
}

body .gwd-cuadroVerdeAnimation {
  -webkit-animation: gwd-cuadroVerdeAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  -moz-animation: gwd-cuadroVerdeAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  animation: gwd-cuadroVerdeAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
}

@-webkit-keyframes gwd-cuadroAzulAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #3073A9;
    box-shadow: 0 0 0.3em #3073A9, 0 0 0.3em #3073A9, 0 0 0.3em #3073A9;
    -webkit-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #3073A9;
    box-shadow: 0 0 0.3em #3073A9, 0 0 0.3em #3073A9, 0 0 0.3em #3073A9;
    -webkit-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }
}

@-moz-keyframes gwd-cuadroAzulAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #3073A9;
    box-shadow: 0 0 0.3em #3073A9, 0 0 0.3em #3073A9, 0 0 0.3em #3073A9;
    -moz-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #3073A9;
    box-shadow: 0 0 0.3em #3073A9, 0 0 0.3em #3073A9, 0 0 0.3em #3073A9;
    -moz-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }
}

@keyframes gwd-cuadroAzulAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #3073A9;
    box-shadow: 0 0 0.3em #3073A9, 0 0 0.3em #3073A9, 0 0 0.3em #3073A9;
    animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #3073A9;
    box-shadow: 0 0 0.3em #3073A9, 0 0 0.3em #3073A9, 0 0 0.3em #3073A9;
    animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }
}

body .gwd-cuadroAzulAnimation {
  -webkit-animation: gwd-cuadroAzulAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  -moz-animation: gwd-cuadroAzulAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  animation: gwd-cuadroAzulAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
}

@-webkit-keyframes gwd-cuadroAmarilloAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #EBCE24;
    box-shadow: 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24;
    -webkit-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #EBCE24;
    box-shadow: 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24;
    -webkit-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }
}

@-moz-keyframes gwd-cuadroAmarilloAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #EBCE24;
    box-shadow: 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24;
    -moz-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #EBCE24;
    box-shadow: 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24;
    -moz-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }
}

@keyframes gwd-cuadroAmarilloAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #EBCE24;
    box-shadow: 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24;
    animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #EBCE24;
    box-shadow: 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24, 0 0 0.3em #EBCE24;
    animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #E4E4E4;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }
}

body .gwd-cuadroAmarilloAnimation {
  -webkit-animation: gwd-cuadroAmarilloAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  -moz-animation: gwd-cuadroAmarilloAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  animation: gwd-cuadroAmarilloAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
}

@-webkit-keyframes gwd-cuadroNegroAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #615349;
    box-shadow: 0 0 0.3em #615349, 0 0 0.3em #615349, 0 0 0.3em #615349;
    -webkit-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #615349;
    box-shadow: 0 0 0.3em #615349, 0 0 0.3em #615349, 0 0 0.3em #615349;
    -webkit-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -webkit-animation-timing-function: linear;
  }
}

@-moz-keyframes gwd-cuadroNegroAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #615349;
    box-shadow: 0 0 0.3em #615349, 0 0 0.3em #615349, 0 0 0.3em #615349;
    -moz-animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #615349;
    box-shadow: 0 0 0.3em #615349, 0 0 0.3em #615349, 0 0 0.3em #615349;
    -moz-animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    -moz-animation-timing-function: linear;
  }
}

@keyframes gwd-cuadroNegroAnimation_gwd-keyframes {
  0% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  25% {
    border: solid 0.2em #615349;
    box-shadow: 0 0 0.3em #615349, 0 0 0.3em #615349, 0 0 0.3em #615349;
    animation-timing-function: linear;
  }

  50% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }

  75% {
    border: solid 0.2em #615349;
    box-shadow: 0 0 0.3em #615349, 0 0 0.3em #615349, 0 0 0.3em #615349;
    animation-timing-function: linear;
  }

  100% {
    border: solid 0.2em #D5CCC6;
    box-shadow: 0 0 0.3em white, 0 0 0.3em white, 0 0 0.3em white;
    animation-timing-function: linear;
  }
}

body .gwd-cuadroNegroAnimation {
  -webkit-animation: gwd-cuadroNegroAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  -moz-animation: gwd-cuadroNegroAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
  animation: gwd-cuadroNegroAnimation_gwd-keyframes 2s linear 0s infinite normal forwards;
}

.cardMenu-Referencia {
  background: #FFF;
  border: 1px solid #ccc !important;
  text-align: center;
  height: 120px;
}

  .cardMenu-Referencia:first-child:hover {
    border: 1px solid orangered !important;
    padding: 5px 5px 5px 5px !important;
  }

  .cardMenu-Referencia:first-child:active {
    border-bottom: 1px solid orangered !important;
    padding: 5px 5px 5px 5px !important;
  }

  .cardMenu-Referencia .titulo-Referencia {
    /*background: #6b45bc;*/
    background: orangered;
    color: #FFFFFF;
    position: absolute;
    font-weight: 500;
    border-radius: 4px;
    text-align: center;
    top: -1.5rem;
    left: 50%;
    width: 80%;
    transform: translateX(-50%);
    opacity: 0.95;
    line-height: 1 !important;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.1rem;
  }

  .cardMenu-Referencia .botones-Referencia-Individual {
    position: absolute;
    bottom: -1rem;
    left: 50%;
    width: 95%;
    transform: translateX(-50%);
  }

  .cardMenu-Referencia .botones-Referencia {
    position: absolute;
    bottom: -0.50rem;
    left: 50%;
    width: 95%;
    transform: translateX(-50%);
  }

  .cardMenu-Referencia img {
    height: 100%;
    width: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
